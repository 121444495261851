import React from 'react'

function Resume() {
    return (
        <div className='acheivment-bg'>

            <div className=''>
            
            <span className='flex justify-center my-8' id='myproject-title'>My<span id='myproject-title2'>Achievements</span> </span>

            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Award for Academic Perseverance in Computer Science </span>
                <span id='achievement-text' className='m-auto'>(2022- 2023)</span>
                <span className='m-auto' id='subtext-ach'>Toronto Metropolitan University</span>

            </div>
            
            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Dean’s List - Faculty of Science (2021- 2022)</span>
                <span className='m-auto' id='subtext-ach'>Toronto Metropolitan University</span>

            </div>


            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Dean’s Award for Creativity in the Faculty of Science</span>
                <span className='m-auto' id='subtext-ach'>Toronto Metropolitan University</span>

            </div>

            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Hackathon Winner - Ciena AI for Accessibility</span>
                <span className='m-auto' id='subtext-ach'>UOttawaHack 4</span>

            </div>

            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Ryerson General Entrance Scholarship</span>
                <span className='m-auto' id='subtext-ach'>95%+ average</span>

            </div>

            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Ontario Scholar</span>
                <span className='m-auto' id='subtext-ach'>2021</span>

            </div>

            <div className='flex flex-col m-auto mb-4' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Media Arts Award</span>
                <span className='m-auto' id='subtext-ach'>2021</span>
            </div>

            <div className='flex flex-col m-auto ' id='acheivment-box' >

                <span id='achievement-text' className='m-auto'>Honor roll recipient</span>
                <span className='m-auto' id='subtext-ach'>2016-2020</span>

            </div>
            
            <div className='pb-4'></div>

            </div>
        </div>
    )
}

export default Resume
