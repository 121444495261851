import React from "react";
import {
  AiFillGithub,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineMail,
} from "react-icons/ai";

function Landing() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="">
          <div className="flex flex-grow flex-col md:flex-row justify-between items-center px-10 pt-24 lg:px-60 lg:pt-20">
            <div className="">
              <h1 className="font-sans text-base font-semibold">
                👋 Hello! My name is
              </h1>
              <h1 className="font-sans text-3xl md:text-6xl font-semibold mb-4">
                Diangelo<span className="text-purple-500">.</span>
              </h1>
              <p className="md:text-lg mb-4 text-gray-800 font-medium">
                I'm a computer science undergraduate from{" "}
                <a
                  href="https://www.google.com/maps/place/Toronto,+ON/@43.7184038,-79.5181407,11z/data=!4m5!3m4!1s0x89d4cb90d7c63ba5:0x323555502ab4c477!8m2!3d43.653226!4d-79.3831843"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-500"
                >
                  Toronto, Ontario
                </a>{" "}
                who 💜s programming, video-games & athletics.
              </p>
              <p className="md:text-lg mb-4 text-gray-800 font-medium">
                I am currently employed as a{" "}
                <span className="text-purple-500">Full Stack Developer</span>{" "}
                for V2 Cloud, the simplest virtual desktop solution for
                businesses.
                <br /> You can find my{" "}
                <a
                  a
                  href="https://drive.google.com/file/d/1zxbdj-z85LU3CHud22hr3CLo7A8jwAFb/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-500"
                >
                  <span> resume</span>
                </a>{" "}
                here.
              </p>
              <p className="md:text-lg mb-4 text-gray-800 font-medium">
                I go by{" "}
                <a
                  href="https://www.instagram.com/diang.elo/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-500"
                >
                  @diang.elo
                </a>{" "}
                on the interwebs.
              </p>
              <div>
                <ul>
                  <a
                    href="https://www.instagram.com/diang.elo/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li className="inline-block">
                      <AiOutlineInstagram size="1.7em" />
                    </li>
                  </a>
                  <a
                    href="https://github.com/diang-elo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li className="inline-block">
                      <AiFillGithub size="1.7em" />
                    </li>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/diangelo-da-silva-ab2656204/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li className="inline-block">
                      <AiOutlineLinkedin size="1.7em" />
                    </li>
                  </a>
                  <a href="mailto:diangelodasilva@hotmail.com">
                    <li className="inline-block">
                      <AiOutlineMail size="1.7em" />
                    </li>
                  </a>
                </ul>
              </div>
            </div>

            <div className="flex-none">
              <img
                className=""
                src="/images/meboth.png"
                alt="Diangelo bitmoji"
              />
            </div>
          </div>
          <div className="background-icons">
            <img src="/images/ai.png" alt="ai Logo" />
            <img src="/images/css.png" alt="css Logo" />
            <img src="/images/html.png" alt="html Logo" />
            <img src="/images/java.png" alt="java Logo" />
            <img src="/images/js.png" alt="js Logo" />
            <img src="/images/ps.png" alt="ps Logo" />
            <img src="/images/python.png" alt="python Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
