import './App.css';
import Landing from './components/Landing';
import Myprojects from './components/Myprojects';
import Resume from './components/Resume';

function App() {
  return (
    <div>

      <div id='header'>
        <div>
          Diangelo
        </div>
      </div>

      <Landing />
      <Myprojects/>
      <Resume/>
      
    </div>
  );
}

export default App;
